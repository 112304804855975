import { Box, Button } from "@mui/material";
import React from "react";
import { useState } from "react";

const DashboardDataToggleComponent = ({ setGraphDuration }) => {
  const [isActive, setIsActive] = useState("TODAY");

  return (
    <Box
      sx={{
        display: "flex",
        "& .MuiButton-root": { mx: 0.5 },
      }}
    >
      <Button
        variant="outlined"
        sx={{
          background: isActive && isActive === "TODAY" ? "#4caf50" : "#f5f5f5",
          fontWeight: "bold",
          border: "1px solid #4caf50",
          color: isActive && isActive === "LAST" ? "black" : "black",
          "&:hover": {
            border: "1px solid #388e3c",
            color: "#ffffff",
            background: "#388e3c",
          },
        }}
        onClick={() => {
          setIsActive("TODAY");
          setGraphDuration("TODAY");
        }}
      >
        Today
      </Button>
      <Button
        variant="outlined"
        color="warning"
        sx={{
          background: isActive && isActive === "THIS" ? "#ff9800" : "#ffffff",
          fontWeight: "bold",
          border: "1px solid #ff9800",
          color: isActive && isActive === "LAST" ? "black" : "black",
          "&:hover": {
            border: "1px solid #ff9800",
            color: "black",
            background: "#e65100",
          },
        }}
        onClick={() => {
          setGraphDuration("THIS");
          setIsActive("THIS");
        }}
      >
        This
      </Button>
      <Button
        variant="outlined"
        sx={{
          // 00bf78
          background: isActive && isActive === "LAST" ? "#00bf7a" : "#ffffff",
          fontWeight: "bold",
          border: "1px solid #00bf78",
          color: isActive && isActive === "LAST" ? "black" : "black",
          "&:hover": {
            border: "1px solid #00bf7a",
            color: "#ffffff",
            background: "#00bf7a",
          },
        }}
        onClick={() => {
          setGraphDuration("LAST");
          setIsActive("LAST");
        }}
      >
        Last
      </Button>
      {/* <ToggleButtonGroup
        orientation="horizontal"
        value={graphDuration}
        exclusive
        onChange={handleChange}
      >
        <ToggleButton
        value="TODAY"
        aria-label="list"
        className="custome-toggle cm-hover"
      >
        Today
      </ToggleButton>
        <ToggleButton
          value="THIS"
          aria-label="module"
          className="custome-toggle cm-hover"
        >
          This
        </ToggleButton>
        <ToggleButton
          value="LAST"
          aria-label="quilt"
          className="custome-toggle cm-hover"
        >
          Last
        </ToggleButton>
      </ToggleButtonGroup> */}
    </Box>
  );
};

export default DashboardDataToggleComponent;
